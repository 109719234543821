<template>
  <v-form
    ref="formAddress"
    class="customer-input-address"
  >
    <v-row>
      <v-col cols="12" sm="12" md="5" class="pb-0">
        <v-autocomplete
          v-model="inputCountry"
          :filter="searchFunctionCountry"
          :rules="[ countryRule ]"
          :items="countries"
          return-object
          height="32"
          label="Land*"
          item-text="name"
        >
          <template v-slot:selection="data">
            <v-avatar left>
              <span :class="'fi fi-' + data.item.iso_code.toLowerCase()"></span>
            </v-avatar>
            {{ data.item.name }}
          </template>
          <template v-slot:item="data">
            <template>
              <v-list-item-avatar primary>
                <span :class="'fi fi-' + data.item.iso_code.toLowerCase()"></span>
              </v-list-item-avatar>
              <v-list-item-content primary>
                <v-list-item-title v-html="data.item.name"></v-list-item-title>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
        <div v-if="isFetchingCountries">
          <v-skeleton-loader
            class="skeleton-loader-country-icon"
            type="avatar"
          ></v-skeleton-loader>
          <v-skeleton-loader
            class="skeleton-loader-country-icon"
            type="avatar"
          ></v-skeleton-loader>
          <v-skeleton-loader
            class="skeleton-loader-country-icon"
            type="avatar"
          ></v-skeleton-loader>
          <v-skeleton-loader
            class="skeleton-loader-country-icon"
            type="avatar"
          ></v-skeleton-loader>
        </div>
        <div v-else>
          <v-btn
            v-for="cntr in commonCountries"
            :key="cntr.id"
            fab
            small
            tabindex="-1"
            :color="(inputCountry != null && inputCountry.id === cntr.id) ? 'primary lighten-3': 'primary lighten-5'"
            :elevation="(inputCountry != null && inputCountry.id === cntr.id) ? '0' : '5'"
            :class="'mr-2 mb-2'"
            @click="setCountry(cntr)"
          >
            <span :class="'fi fi-' + cntr.iso_code.toLowerCase()"></span>
          </v-btn>
        </div>
      </v-col>
      <v-col cols="12" sm="12" md="7" class="pb-0">
        <v-autocomplete
          label="Adressökning"
          v-model="googleAddress.address"
          :items="googleAddress.predictions"
          item-text="address"
          :search-input.sync="googleAddress.search"
          :loading="googleAddress.isLoading"
          return-object
          no-filter
          hide-no-data
          @change="getPlace"
        >
          <template v-slot:append>
            <img
              height="12px"
              style="margin-top: 6px;"
              :src="require('@/assets/images/googleplaces.png')"
            >
          </template>
        </v-autocomplete>
        <v-btn
          v-show="!isShowAllFields"
          small
          tabindex="-1"
          color="primary"
          outlined
          class="float-right"
          @click="showAllFields"
        >
          Fyll i manuellt
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-show="isShowAllFields">
      <v-col cols="12" sm="12" md="7" class="">
        <v-text-field
          label="Gata"
          v-model="inputStreetName"
          :rules="[ streetNameRule ]"
          :loading="isFetchingPlace"
          :class="{ 'sub-errors-for-warning': streetNameValidation.hasWarning }"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="5" class="">
        <v-text-field
          label="Gatunummer"
          v-model="inputStreetNumber"
          :rules="[ streetNumberRule ]"
          :loading="isFetchingPlace"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="5" class="">
        <v-text-field
          label="Postkod"
          v-model="inputZip"
          :rules="[ zipRule ]"
          :loading="isFetchingPlace"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="7" class="">
        <v-text-field
          label="Stad"
          v-model="inputCity"
          :rules="[ cityRule ]"
          :loading="isFetchingPlace"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<style lang="scss">
  .customer-input-address {
    .gm-control-active {
      width: 20px !important;
      height: 20px !important;
    }
    .gm-inset-hover-enabled {
      display: none !important;
    }
    .skeleton-loader-country-icon {
      border-radius: 0;
      display: inline-block;
      margin-right: 8px;
      &>div {
        width: 40px;
        height: 40px;
      }
    }
  }
</style>

<script>
import ValidationService from '@/services/ValidationService.js'
import { mapState } from 'vuex'

export default {
  name: 'CustomerInputAddress',
  props: {
    streetName: String,
    streetNumber: String,
    zip: String,
    city: String,
    country: Object,
    forceValidation: Number,
    forceResetValidation: Number
  },
  data: () => ({
    validation: {
      hasChanges: false,
      isLoading: false,
      isValidated: false,
      hasError: false,
      hasWarning: false,
      errorMessage: '',
      suggestions: []
    },
    streetNameValidation: {
      hasChanges: false,
      isValidated: false,
      hasError: false,
      hasWarning: false,
      errorMessage: ''
    },
    streetNumberValidation: {
      hasChanges: false,
      isValidated: false,
      hasError: false,
      hasWarning: false,
      errorMessage: ''
    },
    zipValidation: {
      hasChanges: false,
      isValidated: false,
      hasError: false,
      hasWarning: false,
      errorMessage: ''
    },
    cityValidation: {
      hasChanges: false,
      isValidated: false,
      hasError: false,
      hasWarning: false,
      errorMessage: ''
    },
    countryValidation: {
      hasChanges: false,
      isValidated: false,
      hasError: false,
      hasWarning: false,
      errorMessage: ''
    },
    hasClickedManualInput: false,
    disableGoogleAddressSearch: false,
    googleAddress: {
      session: '0',
      address: null,
      search: '',
      isLoading: false,
      predictions: []
    },
    isFetchingPlace: false,
    originalStreetName: '',
    originalStreetNumber: '',
    originalZip: '',
    originalCity: '',
    originalCountry: null
  }),
  computed: {
    ...mapState(
      {
        countries: state => state.country.countries,
        isFetchingCountries: state => state.country.isFetchingCountries
      }
    ),
    inputStreetName: {
      get: function () {
        return this.streetName
      },
      set: function (val) {
        this.$emit('set-street-name', val)
      }
    },
    inputStreetNumber: {
      get: function () {
        return this.streetNumber
      },
      set: function (val) {
        this.$emit('set-street-number', val)
      }
    },
    inputZip: {
      get: function () {
        return this.zip
      },
      set: function (val) {
        this.$emit('set-zip', val)
      }
    },
    inputCity: {
      get: function () {
        return this.city
      },
      set: function (val) {
        this.$emit('set-city', val)
      }
    },
    inputCountry: {
      get: function () {
        return this.country
      },
      set: function (val) {
        this.$emit('set-country', val)
      }
    },
    hasGoogleAddress: function () {
      return this.googleAddress.address != null
    },
    commonCountries: function () {
      if (this.countries) {
        return this.countries.filter(c => c.is_common)
      } else {
        return []
      }
    },
    hasInput: function () {
      return !!this.inputStreetName || !!this.inputStreetNumber || !!this.inputZip || !!this.inputCity
    },
    isShowAllFields: function () {
      return this.hasInput || this.hasClickedManualInput
    }
  },
  watch: {
    'googleAddress.search': function () {
      this.googleAddressSearch()
    },
    forceValidation: function () {
      this.validate()
    },
    forceResetValidation: function () {
      this.resetValidation()
    }
  },
  methods: {
    searchFunctionCountry: function (item, queryText, itemText) {
      return item.searchableString.includes(queryText.toLowerCase())
    },
    streetNameRule: function (v) {
      let verdict = ''
      this.streetNameValidation.hasError = false
      this.streetNameValidation.hasWarning = false
      if (v === null || v === '') {
        verdict = ''
      } else if (v != null && v.length > 50) {
        verdict = 'Gatunamn kan vara max 50 tecken'
        this.streetNameValidation.hasError = true
      } else if (!isNaN(parseInt(v.slice(-1)))) {
        // Sista karaktären är en siffra
        verdict = 'Det ser ut som att du kanske har skrivit gatunumret i fel fält'
        this.streetNameValidation.hasWarning = true
      }
      this.streetNameValidation.errorMessage = verdict
      this.streetNameValidation.isValidated = true
      this.streetNameValidation.hasChanges = (this.originalStreetName === v)
      this.updateCommonValidation()
      if (verdict === '') {
        return true
      } else {
        return verdict
      }
    },
    streetNumberRule: function (v) {
      let verdict = ''
      if (v != null && v.length > 15) {
        verdict = 'Gatunummer kan vara max 15 tecken'
      }
      this.streetNumberValidation.hasError = (verdict !== '')
      this.streetNumberValidation.errorMessage = verdict
      this.streetNumberValidation.isValidated = true
      this.streetNumberValidation.hasChanges = (this.originalStreetNumber === v)
      this.updateCommonValidation()
      if (verdict === '') {
        return true
      } else {
        return verdict
      }
    },
    zipRule: function (v) {
      let verdict = ''
      if (v != null && v.length > 50) {
        verdict = 'Postkod kan vara max 50 tecken'
      }
      this.zipValidation.hasError = (verdict !== '')
      this.zipValidation.errorMessage = verdict
      this.zipValidation.isValidated = true
      this.zipValidation.hasChanges = (this.originalZip === v)
      this.updateCommonValidation()
      if (verdict === '') {
        return true
      } else {
        return verdict
      }
    },
    cityRule: function (v) {
      let verdict = ''
      if (v != null && v.length > 50) {
        verdict = 'Stad kan vara max 50 tecken'
      }
      this.cityValidation.hasError = (verdict !== '')
      this.cityValidation.errorMessage = verdict
      this.cityValidation.isValidated = true
      this.cityValidation.hasChanges = (this.originalCity === v)
      this.updateCommonValidation()
      if (verdict === '') {
        return true
      } else {
        return verdict
      }
    },
    countryRule: function (v) {
      let verdict = ''
      if (v == null) {
        verdict = 'Land behövs'
      }
      this.countryValidation.hasError = (verdict !== '')
      this.countryValidation.errorMessage = verdict
      this.countryValidation.isValidated = true
      this.countryValidation.hasChanges = (this.originalCountry?.id === v?.id)
      this.updateCommonValidation()
      if (verdict === '') {
        return true
      } else {
        return verdict
      }
    },
    updateCommonValidation () {
      this.validation.hasChanges = this.streetNameValidation.hasChanges || this.streetNumberValidation.hasChanges || this.zipValidation.hasChanges || this.cityValidation.hasChanges || this.countryValidation.hasChanges
      this.validation.isValidated = (this.streetNameValidation.isValidated && this.streetNumberValidation.isValidated && this.zipValidation.isValidated && this.cityValidation.isValidated && this.countryValidation.isValidated)
      this.validation.isLoading = this.googleAddress.isLoading
      this.validation.hasError = this.streetNameValidation.hasError || this.streetNumberValidation.hasError || this.zipValidation.hasError || this.cityValidation.hasError || this.countryValidation.hasError
      this.validation.hasWarning = this.streetNameValidation.hasWarning
      if (this.streetNameValidation.hasError) {
        this.validation.errorMessage = this.streetNameValidation.errorMessage
      } else if (this.streetNumberValidation.hasError) {
        this.validation.errorMessage = this.streetNumberValidation.errorMessage
      } else if (this.zipValidation.hasError) {
        this.validation.errorMessage = this.zipValidation.errorMessage
      } else if (this.cityValidation.hasError) {
        this.validation.errorMessage = this.cityValidation.errorMessage
      } else if (this.countryValidation.hasError) {
        this.validation.errorMessage = this.countryValidation.errorMessage
      } else if (this.streetNameValidation.hasWarning) {
        this.validation.errorMessage = this.streetNameValidation.errorMessage
      } else {
        this.validation.errorMessage = ''
      }
      this.$emit('set-validation', this.validation)
    },
    validate () {
      return this.$refs.formAddress.validate()
    },
    resetValidation: function () {
      this.$refs.formAddress.resetValidation()
    },
    showAllFields () {
      this.clearSearchValue()
      this.hasClickedManualInput = true
    },
    clearSearchValue () {
      this.disableGoogleAddressSearch = true
      this.googleAddress.search = ''
      this.googleAddress.predictions = []
      this.googleAddress.address = null
      this.disableGoogleAddressSearch = false
    },
    googleAddressSearch () {
      if (this.disableGoogleAddressSearch) {
        return
      }
      if (this.googleAddress.search) {
        const v = this.googleAddress.search
        if (v.length < 4) return
        this.googleAddress.isLoading = true
        this.googleAddress.predictions = []
        ValidationService.searchAddress(this.googleAddress.session, v, this.inputCountry ? this.inputCountry.iso_code : '')
          .then(({ data }) => {
            if (this.googleAddress.search === data.data.search_value) {
              this.googleAddress.session = data.data.session
              this.googleAddress.predictions = data.data.predictions
            }
          })
          .finally(() => {
            this.googleAddress.isLoading = false
          })
      }
    },
    getPlace () {
      if (!this.googleAddress.session || !this.googleAddress.address) {
        return false
      }
      this.disableGoogleAddressSearch = true
      this.isFetchingPlace = true
      ValidationService.getPlace(this.googleAddress.session, this.googleAddress.address.place_id)
        .then(({ data }) => {
          this.googleAddress.session = data.data.session
          this.inputStreetName = data.data.place.street_name ?? ''
          this.inputStreetNumber = data.data.place.street_number ?? ''
          this.inputZip = data.data.place.zip ?? ''
          this.inputCity = data.data.place.city ?? ''
          this.inputCountry = this.countries.find((country) => country.iso_code === data.data.place.country)
        })
        .finally(() => {
          this.disableGoogleAddressSearch = false
          this.isFetchingPlace = false
        })
    },
    setCountry: function (country) {
      this.inputCountry = country
    }
  },
  mounted () {
    this.originalStreetName = this.streetName
    this.originalStreetNumber = this.streetNumber
    this.originalZip = this.zip
    this.originalCity = this.city
    this.originalCountry = this.country
  }
}
</script>
