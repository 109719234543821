<template>
  <v-card class="register-credit d-flex flex-column full-height px-4 justify-start" flat>
    <v-expansion-panels v-model="expansionState" class="d-flex full-width">
      <!-- Sales items tabell panel -->
      <v-expansion-panel class="mb-2">
        <v-expansion-panel-header class="full-width">
          <div class="px-4 text-subtitle-1">Välj vad som ska krediteras</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="px-0">
          <v-simple-table fixed-header height="215">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left pl-2 pr-0">
                    Kreditera
                  </th>
                  <th class="text-left">
                    Produktnamn
                  </th>
                  <th class="text-right">
                    Antal
                  </th>
                  <th class="text-right">
                    Pris
                  </th>
                  <th class="text-right">
                    Summa
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="salesItem in creditSalesItems"
                  :key="salesItem.original_sales_item_id"
                >
                  <td style="width: 60px;" class="pr-0">
                    <v-checkbox
                      v-model="salesItem.is_selected"
                      hide-details
                      class="mt-0 pt-0"
                    ></v-checkbox>
                  </td>
                  <td style="max-width: 200px;">
                    <div :class="{ 'pt-2': !!salesItem.caption }">
                      <v-chip
                        :color="salesItem.product.gui_color"
                        dark
                      >
                        <div style="text-overflow: ellipsis; width: 100%; overflow: hidden;">{{ salesItem.product.name }}</div>
                      </v-chip>
                    </div>
                    <div v-show="!!salesItem.caption" class="pb-2 pt-1 pl-3 text-caption"><span>{{ salesItem.caption }}</span></div>
                  </td>
                  <td class="text-right" style="width: 80px; padding-left: 0;">
                    <template v-if="salesItem.is_edit_mode">
                      <v-text-field
                        v-model="salesItem.selected_quantity"
                        :hint="`av ${salesItem.original_quantity / 1000}`"
                        persistent-hint
                        type="number"
                        reverse
                        class="mt-0 pt-0"
                      ></v-text-field>
                    </template>
                    <template v-else>
                      {{ salesItem.original_quantity / 1000 }}
                    </template>
                  </td>
                  <td class="text-right" style="width: 110px;">
                    <template v-if="salesItem.is_edit_mode">
                      <v-text-field
                        v-model="salesItem.selected_price"
                        :hint="`av ${salesItem.original_price / 100}kr`"
                        persistent-hint
                        type="number"
                        reverse
                        class="mt-0 pt-0"
                      ></v-text-field>
                    </template>
                    <template v-else>
                      {{ salesItem.original_price / 100 }}
                    </template>
                  </td>
                  <td class="text-right">
                    <template v-if="salesItem.is_edit_mode">
                      {{ salesItem.selected_quantity * salesItem.selected_price }}kr
                    </template>
                    <template v-else>
                      {{ salesItem.original_quantity / 1000 * salesItem.original_price / 100 }}kr
                    </template>
                  </td>
                  <td class="text-right pl-2" style="width: 28px;">
                    <v-btn icon small @click="salesItem.is_edit_mode = !salesItem.is_edit_mode">
                      <v-icon small>{{ salesItem.is_edit_mode ? 'mdi-pen-off' : 'mdi-pen'}}</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- Före/Efter expansion panel -->
      <v-expansion-panel class="full-width mt-2">
        <v-expansion-panel-header class="full-width" v-slot="{ open }">
          <template v-if="!open">
            <div class="d-flex mt-2">
              <div style="border-right: 1px solid #eee; flex: 1;">
                <div class="pt-0 text-center text-subtitle-2">Före kreditering</div>
                <div class="d-flex">
                  <div style="border-right: 1px solid #eee; flex: 1;">
                    <v-list
                      subheader
                      three-line
                    >
                      <v-subheader class="pt-0 text-subtitle-2" style="height: 28px; padding-bottom: 8px;">Originalfaktura</v-subheader>
                      <v-list-item style="min-height: auto;">
                        <v-list-item-content class="pa-0">
                          <v-list-item-title>{{ originalInvoiceAmounts.remaining }}</v-list-item-title>
                          <v-list-item-subtitle>Återstående</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </div>
                  <div style="flex: 1;">
                    <v-list
                      subheader
                      three-line
                    >
                      <v-subheader class="pt-0 text-subtitle-2" style="height: 28px; padding-bottom: 8px;">Ny kreditfakt.</v-subheader>
                      <v-list-item style="min-height: auto;">
                        <v-list-item-content class="pa-0">
                          <v-list-item-title>{{ creditInvoiceAmounts.remaining }}</v-list-item-title>
                          <v-list-item-subtitle>Återstående</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </div>
                </div>
              </div>

              <div style="flex: 1;">
                <div class="pt-0 text-center text-subtitle-2">Efter kreditering</div>
                <div class="d-flex">
                  <div style="border-right: 1px solid #eee; flex: 1;">
                    <v-list
                      subheader
                      three-line
                    >
                      <v-subheader class="pt-0 text-subtitle-2" style="height: 28px; padding-bottom: 8px;">Originalfaktura</v-subheader>
                      <v-list-item style="min-height: auto;">
                        <v-list-item-content class="pa-0">
                          <v-list-item-title>{{ postCreditOriginalInvoiceAmounts.remaining }}</v-list-item-title>
                          <v-list-item-subtitle>Återstående</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </div>
                  <div style="flex: 1;">
                    <v-list
                      subheader
                      three-line
                    >
                      <v-subheader class="pt-0 text-subtitle-2" style="height: 28px; padding-bottom: 8px;">Ny kreditfakt.</v-subheader>
                      <v-list-item style="min-height: auto;">
                        <v-list-item-content class="pa-0">
                          <v-list-item-title>{{ postCreditCreditInvoiceAmounts.remaining }}</v-list-item-title>
                          <v-list-item-subtitle>Återstående</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="px-4 text-subtitle-1">Förhandsgranskning</div>
          </template>
        </v-expansion-panel-header>

        <v-expansion-panel-content class="px-0">
          <div class="d-flex mt-2">
            <div style="border-right: 1px solid #eee;">
              <div class="pt-0 text-center text-subtitle-2">Före kreditering</div>
              <div class="d-flex">
                <div style="border-right: 1px solid #eee;">
                  <v-list
                    subheader
                    three-line
                  >
                    <v-subheader class="pt-0 text-subtitle-2" style="height: 28px; padding-bottom: 8px;">Originalfaktura</v-subheader>
                    <v-list-item style="min-height: auto;">
                      <v-list-item-content class="pa-0">
                        <v-list-item-title>{{ originalInvoiceAmounts.total }}</v-list-item-title>
                        <v-list-item-subtitle>Belopp</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item style="min-height: auto;">
                      <v-list-item-content class="pa-0 mt-4">
                        <v-list-item-title>{{ originalInvoiceAmounts.paid }}</v-list-item-title>
                        <v-list-item-subtitle>Betalt</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item style="min-height: auto;">
                      <v-list-item-content class="pa-0 mt-4">
                        <v-list-item-title>{{ originalInvoiceAmounts.credited }}</v-list-item-title>
                        <v-list-item-subtitle>Krediterat</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item style="min-height: auto;">
                      <v-list-item-content class="pa-0 mt-4">
                        <v-list-item-title>{{ originalInvoiceAmounts.remaining }}</v-list-item-title>
                        <v-list-item-subtitle>Återstående</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </div>
                <div>
                  <v-list
                    subheader
                    three-line
                  >
                    <v-subheader class="pt-0 text-subtitle-2" style="height: 28px; padding-bottom: 8px;">Ny kreditfaktura</v-subheader>
                    <v-list-item style="min-height: auto;">
                      <v-list-item-content class="pa-0">
                        <v-list-item-title>{{ creditInvoiceAmounts.total }}</v-list-item-title>
                        <v-list-item-subtitle>Belopp</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item style="min-height: auto;">
                      <v-list-item-content class="pa-0 mt-4">
                        <v-list-item-title>{{ creditInvoiceAmounts.paid }}</v-list-item-title>
                        <v-list-item-subtitle>Betalt</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item style="min-height: auto;">
                      <v-list-item-content class="pa-0 mt-4">
                        <v-list-item-title>{{ creditInvoiceAmounts.credited }}</v-list-item-title>
                        <v-list-item-subtitle>Krediterat</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item style="min-height: auto;">
                      <v-list-item-content class="pa-0 mt-4">
                        <v-list-item-title>{{ creditInvoiceAmounts.remaining }}</v-list-item-title>
                        <v-list-item-subtitle>Återstående</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </div>
              </div>
            </div>

            <div>
              <div class="pt-0 text-center text-subtitle-2">Efter kreditering</div>
              <div class="d-flex">
                <div style="border-right: 1px solid #eee;">
                  <v-list
                    subheader
                    three-line
                  >
                    <v-subheader class="pt-0 text-subtitle-2" style="height: 28px; padding-bottom: 8px;">Originalfaktura</v-subheader>
                    <v-list-item style="min-height: auto;">
                      <v-list-item-content class="pa-0">
                        <v-list-item-title>{{ postCreditOriginalInvoiceAmounts.total }}</v-list-item-title>
                        <v-list-item-subtitle>Belopp</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item style="min-height: auto;">
                      <v-list-item-content class="pa-0 mt-4">
                        <v-list-item-title>{{ postCreditOriginalInvoiceAmounts.paid }}</v-list-item-title>
                        <v-list-item-subtitle>Betalt</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item style="min-height: auto;">
                      <v-list-item-content class="pa-0 mt-4">
                        <v-list-item-title>{{ postCreditOriginalInvoiceAmounts.credited }}</v-list-item-title>
                        <v-list-item-subtitle>Krediterat</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item style="min-height: auto;">
                      <v-list-item-content class="pa-0 mt-4">
                        <v-list-item-title>{{ postCreditOriginalInvoiceAmounts.remaining }}</v-list-item-title>
                        <v-list-item-subtitle>Återstående</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </div>
                <div>
                  <v-list
                    subheader
                    three-line
                  >
                    <v-subheader class="pt-0 text-subtitle-2" style="height: 28px; padding-bottom: 8px;">Ny kreditfaktura</v-subheader>
                    <v-list-item style="min-height: auto;">
                      <v-list-item-content class="pa-0">
                        <v-list-item-title>{{ postCreditCreditInvoiceAmounts.total }}</v-list-item-title>
                        <v-list-item-subtitle>Belopp</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item style="min-height: auto;">
                      <v-list-item-content class="pa-0 mt-4">
                        <v-list-item-title>{{ postCreditCreditInvoiceAmounts.paid }}</v-list-item-title>
                        <v-list-item-subtitle>Betalt</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item style="min-height: auto;">
                      <v-list-item-content class="pa-0 mt-4">
                        <v-list-item-title>{{ postCreditCreditInvoiceAmounts.credited }}</v-list-item-title>
                        <v-list-item-subtitle>Krediterat</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item style="min-height: auto;">
                      <v-list-item-content class="pa-0 mt-4">
                        <v-list-item-title>{{ postCreditCreditInvoiceAmounts.remaining }}</v-list-item-title>
                        <v-list-item-subtitle>Återstående</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </div>
              </div>
            </div>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-card-actions class="mt-auto flex-grow-0 px-0 pb-4">
      <v-spacer></v-spacer>
      <v-btn
        color="success"
        @click="createCreditInvoice"
        :loading="isLoading"
      >
        Skapa kreditfaktura
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'RegisterCredit',
  components: {},
  props: {
    isOpen: Boolean,
    originalInvoice: Object
  },
  data: () => ({
    isStrongValidation: false,
    creditSalesItems: [],
    expansionState: 0
  }),
  computed: {
    ...mapState(
      {
        isLoadingRegisterCreditInvoiceIds: state => state.invoice.isLoadingRegisterCreditInvoiceIds
      }
    ),
    isLoading: function () {
      return this.originalInvoice && this.isLoadingRegisterCreditInvoiceIds.includes(this.originalInvoice.id)
    },
    expansionOpen: function () {
      return this.expansionState !== undefined && this.expansionState !== null
    },
    invoiceName: function () {
      if (this.isIncomingPayment) {
        // fakturor eller kontantfaktura
        return `Faktura ${this.invoice.invoice_number}`
      } else {
        return `Kreditfaktura ${this.invoice.invoice_number}`
      }
    },
    creditAmount: function () {
      const amount = this.creditSalesItems.reduce((accumulatedAmount, salesItem) => {
        if (!salesItem.is_selected) {
          return accumulatedAmount
        }
        if (salesItem.is_edit_mode) {
          accumulatedAmount += Math.round(salesItem.selected_quantity * salesItem.selected_price * 100)
        } else {
          accumulatedAmount += Math.round(salesItem.original_quantity / 1000 * salesItem.original_price)
        }
        return accumulatedAmount
      }, 0)

      // Alltid matchande belopp när hela fakturan krediteras
      if (this.originalInvoice && amount + this.originalInvoice.rounding === this.originalInvoice.total_amount) {
        return -amount - this.originalInvoice.rounding
      }

      const applicableAmount = this.originalInvoice.remaining_amount

      if (applicableAmount >= amount) {
        // Om fakturan delkrediteras och kvittas så kvittas exakt belopp om det går
        return -amount
      } else {
        // Kreditfakturan kommer få öresavrundning om den ej kvittas, detta ska inte påverka ursprungsfakturans belopp
        return -(Math.round(amount / 100) * 100)
      }

      // Om beloppet är under 1kr så avrundas det bort i alla fall
    },
    originalInvoiceAmounts: function () {
      if (!this.originalInvoice) {
        return {}
      }
      return {
        total: `${this.originalInvoice.total_amount / 100}kr`,
        paid: `${this.originalInvoice.paid_amount / 100}kr`,
        credited: `${this.originalInvoice.credited_amount / 100}kr`,
        remaining: `${this.originalInvoice.remaining_amount / 100}kr`
      }
    },
    creditInvoiceAmounts: function () {
      return {
        total: `${this.creditAmount / 100}kr`,
        paid: '0kr',
        credited: '0kr',
        remaining: `${this.creditAmount / 100}kr`
      }
    },
    postCreditOriginalInvoiceAmounts: function () {
      if (!this.originalInvoice) {
        return {}
      }
      // Belopp som kommer krediteras ursprungsfaktura, det lägsta (i absoluta tal) av det krediterade belopp och hur mycket som återstår på fakturan
      // Positivt belopp
      const appliedCreditAmount = Math.min(Math.abs(this.creditAmount), this.originalInvoice.remaining_amount)
      let remaining = (this.originalInvoice.remaining_amount - appliedCreditAmount)
      if (Math.abs(remaining) < 100) {
        remaining = 0
      }
      return {
        total: `${this.originalInvoice.total_amount / 100}kr`,
        paid: `${this.originalInvoice.paid_amount / 100}kr`,
        credited: `${(this.originalInvoice.credited_amount + appliedCreditAmount) / 100}kr`,
        remaining: `${remaining / 100}kr`
      }
    },
    postCreditCreditInvoiceAmounts: function () {
      if (!this.originalInvoice) {
        return {}
      }
      // Belopp som kommer krediteras ursprungsfaktura, det lägsta (i absoluta tal) av det krediterade belopp och hur mycket som återstår på fakturan
      // negativt belopp
      const appliedCreditAmount = Math.min(Math.abs(this.creditAmount), this.originalInvoice.remaining_amount) * -1

      let remaining = (this.creditAmount - appliedCreditAmount)
      if (Math.abs(remaining) < 100) {
        remaining = 0
      }

      return {
        total: `${this.creditAmount / 100}kr`,
        paid: '0kr',
        credited: `${appliedCreditAmount / 100}kr`,
        remaining: `${remaining / 100}kr`
      }
    }
  },
  methods: {
    createCreditSalesItems: function () {
      this.creditSalesItems = []
      if (!this.originalInvoice) {
        return false
      }
      this.originalInvoice.sales_items.forEach(originalSalesItem => {
        this.creditSalesItems.push({
          is_selected: false,
          is_edit_mode: false,
          original_sales_item_id: originalSalesItem.id,
          is_accommodation_charge: originalSalesItem.is_accommodation_charge,
          product: Object.assign({}, originalSalesItem.product),
          original_quantity: originalSalesItem.quantity,
          selected_quantity: originalSalesItem.quantity / 1000,
          original_price: originalSalesItem.price,
          selected_price: originalSalesItem.price / 100,
          caption: originalSalesItem.caption
        })
      })
    },
    close: function () {
      this.$emit('close')
    },
    resetForm: function () {
      this.isStrongValidation = false
      this.expansionState = 0
      //
    },
    validate: function () {
      if (this.creditSalesItems.filter(salesItem => salesItem.is_selected).length === 0) {
        this.$store.dispatch('snackbars/createSnackbar', {
          color: 'error',
          text: 'Välj vilka rader på fakturan du vill kreditera'
        })
        return false
      }
      if (this.creditAmount >= 0) {
        this.$store.dispatch('snackbars/createSnackbar', {
          color: 'error',
          text: 'Den resulterande kreditfakturan måste ha ett negativt belopp'
        })
        return false
      }
      if (this.creditSalesItems.filter(salesItem => salesItem.selected_price <= 0).length > 0) {
        this.$store.dispatch('snackbars/createSnackbar', {
          color: 'error',
          text: 'Negativa priser är inte tillåtet'
        })
        return false
      }
      return true
    },
    createCreditInvoice: function () {
      this.isStrongValidation = true
      const isValid = this.validate()
      if (isValid) {
        const originalInvoiceId = this.originalInvoice.id
        const creditSalesItems = this.creditSalesItems.filter(salesItem => salesItem.is_selected).map(salesItem => {
          return {
            product_id: salesItem.product.id,
            quantity: salesItem.is_edit_mode ? Math.round(salesItem.selected_quantity * 1000) : salesItem.original_quantity,
            price: salesItem.is_edit_mode ? Math.round(salesItem.selected_price * 100) : salesItem.original_price,
            is_accommodation_charge: salesItem.is_accommodation_charge
          }
        })

        this.$store.dispatch('invoice/createCreditInvoice', {
          creditSalesItems,
          originalInvoiceId
        })
          .then(() => {
            this.close()
            this.resetForm()
          })
      }
    }
  },
  watch: {
    isOpen: function () {
      if (this.isOpen) {
        // Skapar creditSalesItems utifrån originalInvoices sales_items
        this.createCreditSalesItems()
      } else {
        // återställer formuläret när componenten stängs
        this.resetForm()
      }
    }
  }
}
</script>
