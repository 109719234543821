<template>
  <v-card class="invoice-checkout d-flex flex-column full-height px-4 justify-start" flat>
    <div class="mt-2">
      <v-form
        @submit.prevent
        ref="formAmount"
      >
        <v-text-field
          v-model="inputAmount"
          :label="amountLabel"
          append-icon="coi-currency-sek"
          outlined
          type="number"
          :hide-details="!amountValidation.hasWarning && !amountValidation.hasError"
          :error-messages="amountValidation.hasError || amountValidation.hasWarning ? [amountValidation.errorMessage] : []"
          :class="{ 'sub-errors-for-warning': amountValidation.hasWarning }"
        ></v-text-field>
      </v-form>
      <v-chip
        :outlined="!isRemainingAmount"
        :class="{ 'mt-2': !amountValidation.hasWarning && !amountValidation.hasError }"
        color="secondary"
        @click="selectRemainingAmount"
      >
        Återstående fakturabelopp
      </v-chip>
    </div>

    <!-- customer credit -->
    <div class="mt-8 flex-grow-1" v-if="isIncomingPayment">
      <div v-if="isFetchingCustomerCredit" class="d-flex">
        <v-progress-circular
          indeterminate
          :size="24"
          color="primary"
          class="mr-4"
        ></v-progress-circular>
        <p>Hämtar kundens tillgodo saldo</p>
      </div>
      <div v-else-if="customerCreditAmount > 0" class="d-flex flex-column full-height">
        <v-alert v-if="!showApplyCreditForm" color="success" text dense class="ma-0">
          <div class="d-flex">
            <v-icon color="success">mdi-account-cash</v-icon>
            <div class="ml-4" style="line-height: 28px;">{{ customerCreditAlertText }}</div>
            <div class="ml-auto my-auto">
              <v-btn outlined color="success" small :disabled="selectedAmount < 0" @click="openApplyCreditForm">Använd tillgodo</v-btn>
            </div>
          </div>
        </v-alert>
        <apply-credit-form
          v-if="showApplyCreditForm"
          v-model="appliedCustomerCredit"
          :customer-credit-available="customerCreditAmount"
          :total-payable="selectedAmount"
          inline-form
          @close="closeApplyCreditForm"
        ></apply-credit-form>
        <v-spacer></v-spacer>
        <div>
          <v-row class="ma-0">
            <v-col class="d-flex py-0">
              <span class="text-subtitle-2">Total:</span>
              <span class="text-subtitle-2 ml-auto pull-right">{{ totalAmountFormatted }}</span>
            </v-col>
          </v-row>
          <v-row class="ma-0">
            <v-col class="d-flex py-0">
              <span class="text-subtitle-2 success--text">Applicerad tillgodo:</span>
              <span class="text-subtitle-2 ml-auto pull-right success--text">{{ appliedCustomerCreditFormatted }}</span>
            </v-col>
          </v-row>
          <v-row class="ma-0">
            <v-col class="d-flex pt-0">
              <span class="text-h5" v-if="totalPayableAmount >= 0" >Att betala:</span>
              <span class="text-h5" v-else >Åter:</span>
              <span class="text-h5 ml-auto pull-right">{{ totalPayableAmountFormatted }}</span>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>

    <v-card-actions class="mt-auto flex-grow-0 pb-4" style="margin-left: -20px; margin-right: -20px;"><!-- negativ margin kompenserar för padding på v-card-actions och knapparna -->
      <template v-if="showPayWithCreditButton">
        <v-btn
          x-large
          class="px-5 btn-payment-method ml-auto mr-2"
          color="success"
          @click="startPayment"
          :loading="isLoadingPayment"
        >
          Slutför betalning
        </v-btn>
      </template>
      <payment-methods
        v-else
        v-model="selectedPaymentMethod"
        :buttons-shown="3"
        :is-loading="isLoadingPayment || isFetchingCustomerCredit"
        @payment-method-selected="startPayment"
      ></payment-methods>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'

import PaymentMethods from '@/components/register/PaymentMethods.vue'
import ApplyCreditForm from '@/components/customer/ApplyCreditForm.vue'

import CustomerCreditService from '@/services/CustomerCreditService.js'

export default {
  name: 'InvoiceCheckout',
  components: {
    PaymentMethods,
    ApplyCreditForm
  },
  props: {
    isOpen: Boolean,
    invoice: Object
  },
  data: () => ({
    selectedPaymentMethod: null,
    selectedAmount: 0,
    isStrongValidation: false,
    amountValidation: {
      hasError: false,
      hasWarning: false,
      errorMessage: ''
    },
    customerCreditAmount: 0,
    appliedCustomerCredit: 0,
    isFetchingCustomerCredit: true,
    showApplyCreditForm: false
  }),
  computed: {
    ...mapState(
      {
        isLoadingPayment: state => state.invoice.isLoadingPayment,
        triggerRetryPayment: state => state.invoice.triggerRetryPayment,
        terminalError: state => state.posTerminal.terminalError
      }
    ),
    inputAmount: {
      get: function () {
        return this.selectedAmount / 100
      },
      set: function (val) {
        let amount = Math.round(val * 100)
        if (Number.isNaN(amount)) {
          amount = 0
        }
        this.selectedAmount = amount
      }
    },
    totalPayableAmount: function () {
      const payableAmount = this.selectedAmount - this.appliedCustomerCredit
      return Math.sign(payableAmount) * Math.round(Math.abs(payableAmount / 100)) * 100 // Math.round avrundar -1.5 till -1 men vi vill avrunda till -2, viktigt att det blir samma vid köp och retur
    },
    isIncomingPayment: function () {
      return this.invoice && !this.invoice.is_credit_invoice
    },
    totalAmountFormatted: function () {
      return `${this.selectedAmount / 100} kr`
    },
    totalPayableAmountFormatted: function () {
      return `${this.totalPayableAmount / 100} kr`
    },
    appliedCustomerCreditFormatted: function () {
      return `- ${this.appliedCustomerCredit / 100} kr`
    },
    showPayWithCreditButton: function () {
      return this.selectedAmount > 0 && this.totalPayableAmount === 0
    },
    amountLabel: function () {
      if (this.isIncomingPayment) {
        // fakturor eller kontantfaktura
        return 'Att betala'
      } else {
        return 'Att återbetala'
      }
    },
    customerCreditAlertText: function () {
      if (this.appliedCustomerCredit === 0) {
        return `Kunden har ${this.customerCreditAmount / 100} kr tillgodo`
      } else {
        const remainingAmount = this.customerCreditAmount - this.appliedCustomerCredit
        return `Efter genomförd betalning kommer kunden ha ${remainingAmount / 100} kr återstående tillgodo`
      }
    },
    remainingAmount: function () {
      if (!this.invoice) {
        return 0
      }
      return Math.round(Math.abs(this.invoice.remaining_amount) / 100) * 100 // använder inte sign för beloppet ska anges positivt även vid återbetalning
    },
    isRemainingAmount: function () {
      return this.invoice && this.remainingAmount > 0 && this.remainingAmount === this.selectedAmount
    }
  },
  methods: {
    close: function () {
      this.$emit('close')
    },
    validateAmount: function () {
      const amount = this.selectedAmount
      this.amountValidation.hasError = false
      this.amountValidation.hasWarning = false

      if (amount === '' || amount === 0) {
        if (this.isStrongValidation) {
          this.amountValidation.errorMessage = 'Fyll i ett belopp'
          this.amountValidation.hasError = true
          return false
        }
        return true
      }
      const n = Number.parseFloat(amount)
      if (Number.isNaN(n)) {
        this.amountValidation.errorMessage = 'Beloppet måste vara ett tal'
        this.amountValidation.hasError = true
        return false
      }
      if (amount <= 0) {
        if (this.isIncomingPayment) {
          this.amountValidation.errorMessage = 'Beloppet måste vara positivt. För återbetalningar välj istället att kreditera hela eller delar av fakturan och gör återbetalning på den skapade kreditfaktura'
        } else {
          this.amountValidation.errorMessage = 'Beloppet måste vara positivt. För utbetalningar på kreditfakturor ska det angivna beloppet vara positivt'
        }
        this.amountValidation.hasError = true
        return false
      }
      if (amount % 100 !== 0) {
        this.amountValidation.errorMessage = 'Beloppet måste vara hela kronor'
        this.amountValidation.hasError = true
        return false
      }
      if (this.invoice && amount > this.remainingAmount) {
        if (this.isIncomingPayment) {
          this.amountValidation.errorMessage = 'Beloppet är högre än återstående belopp på fakturan. Överskottet sparas som tillgodo på kundens konto'
          this.amountValidation.hasWarning = true
        } else {
          this.amountValidation.errorMessage = 'Utbetalningen kan inte vara större än det återstående beloppet på kreditfakturan'
          this.amountValidation.hasError = true
          return false
        }
      }
      return true
    },
    resetForm: function () {
      this.isStrongValidation = false
      this.selectedAmount = 0
      this.selectedPaymentMethod = null
      this.appliedCustomerCredit = 0
      this.customerCreditAmount = 0
      this.closeApplyCreditForm()
      this.validateAmount()
    },
    openApplyCreditForm: function () {
      this.showApplyCreditForm = true
    },
    closeApplyCreditForm: function () {
      this.showApplyCreditForm = false
    },
    selectRemainingAmount: function () {
      this.selectedAmount = this.remainingAmount
    },
    getCustomerCreditAmount: function () {
      // hämta customer credit
      const customerId = this.invoice.customer_id
      this.isFetchingCustomerCredit = true
      this.appliedCustomerCredit = 0
      this.customerCreditAmount = 0
      return CustomerCreditService.getCustomerCredit({ customerId, includeTimeline: false })
        .then(({ data }) => {
          console.log('getCustomerCredit data', data)
          if (data.status === 'success') {
            this.customerCreditAmount = data.data.customer_credit
          }
        })
        .finally(() => {
          this.isFetchingCustomerCredit = false
        })
    },
    startPayment: function () {
      this.isStrongValidation = true
      if (!this.validateAmount()) {
        return false
      }

      if (this.selectedPaymentMethod && this.selectedPaymentMethod.gateway === 'integrated_terminal') {
        // Ökar chansen för att socketen fungerar, den öppnas bara om den inte redan är öppen
        // Stängs av sig själv ibland så detta säkerställer att den är ok precis innan betalning
        // Vi släpper igenom det även om socketen för närvarande inte kan ansluta (p.g.a. nets)
        // för betalningen kommer fungera ändå fast utan att visa meddelandena från skärmen
        this.$store.dispatch('posTerminal/forceVerifyTerminalConnection')
      } else if (this.selectedPaymentMethod && (this.selectedPaymentMethod.gateway === 'invoicing' || this.selectedPaymentMethod.gateway === 'customer_credit')) {
        // Ska inte kunna fakturera härifrån, betalmetoden visas inte, men för en extra kontroll
        return false
      }
      this.$store.commit('posTerminal/SET_RETRY_PATH', 'invoice/TRIGGER_RETRY_PAYMENT')
      this.$store.dispatch('invoice/initPayment', {
        paymentMethod: this.selectedPaymentMethod,
        amount: this.totalPayableAmount + this.appliedCustomerCredit,
        customerCreditAmount: this.appliedCustomerCredit,
        invoice: this.invoice
      })
        .then(() => {
          if (!this.terminalError) {
            // Stänger inte overlay om terminalen gav en error, går att klicka försök igen vid error
            this.close()
          }
        })
    }
  },
  watch: {
    isOpen: function () {
      if (!this.isOpen) {
        // återställer formuläret när componenten stängs
        this.resetForm()
      } else {
        if (this.isIncomingPayment) {
          this.getCustomerCreditAmount()
        } else {
          this.isFetchingCustomerCredit = false
        }
      }
    },
    selectedAmount: function () {
      this.validateAmount()
    },
    triggerRetryPayment: function () {
      this.startPayment()
    }
  }
}
</script>
