<template>
  <v-dialog
    v-model="show"
    persistent
    max-width="340"
  >
    <v-card>
      <v-card-title class="text-h5" style="word-break: keep-all;">
        Uppdatering krävs
      </v-card-title>
      <v-card-text v-if="updateStarted">
        Programmet uppdateras, vänligen vänta
        <v-progress-linear
          indeterminate
          color="green"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
      <v-card-text v-else>
        {{ updateText }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          text
          :disabled="updateStarted"
          @click="update"
        >
        {{ isElectron ? 'Uppdatera' : 'Ladda om'}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'UpdateDialog',
  data: () => ({
    updateStarted: false
  }),
  computed: {
    ...mapState(
      {
        show: state => state.showUpdateDialog,
        isElectron: state => state.isElectron
      }
    ),
    updateText: function () {
      return this.isElectron ? 'Uppdatering krävs för att fortsätta, programmet kommer att startas om' : 'Uppdatering krävs för att fortsätta, sidan behöver laddas om'
    }
  },
  methods: {
    // Egen anteckning: Kontrollerar uppdatering leder till installation, efter installation hamnar den i waiting till slut activated
    update: async function () {
      if (this.isElectron) {
        // Electron uppdaterar med sin egen metod
        console.log('Electron uppdatering påbörjad')
        this.updateStarted = true
        this.$store.dispatch('updateClient')
      } else if ('serviceWorker' in navigator) {
        // Om webbläsaren har stöd för service workers används PWA uppdatering
        // Webbläsare som Chrome använder PWA/ServiceWorker när det finns även om användaren inte installerar appen blir den ändå "installerad" fast bara tillgänglig i webbläsaren
        console.log('PWA uppdatering påbörjad')
        this.updateStarted = true
        await this.handlePWAUpdate()
      } else {
        // Fallback för webbläsare/enheter utan PWA stöd
        console.log('Inget PWA stöd, laddar om sidan')
        window.location.reload()
      }
    },
    async handlePWAUpdate () {
      setTimeout(() => {
        // Denna uppdatering ska bara ladda ner src mappen så borde inte ta så lång tid
        // Uppdatering av serviceworkers kan fastna eller hamna i ett oväntat state med flera flikar
        // Har troligen slutförts redan annars får man försöka igen, bättre än att det fortsätter ladda
        console.log('Uppdatering troligen fastnat, laddar om sidan')
        window.location.reload()
      }, 30000)
      try {
        const registration = await navigator.serviceWorker.getRegistration()
        if (registration) {
          this.setupUpdateListeners(registration)
          registration.update().then(() => {
            if (registration.installing) {
              registration.installing.addEventListener('statechange', (e) => {
                if (e.target.state === 'installed') {
                  this.checkForWaitingUpdate(registration)
                }
              })
            } else if (registration.waiting) {
              this.checkForWaitingUpdate(registration)
            } else {
              console.log('Ingen uppdatering tillgänglig, laddar om sidan')
              window.location.reload()
            }
          }).catch(error => {
            console.log('Uppdatering misslyckades, laddar om sidan')
            console.error('Service worker update error:', error)
            window.location.reload()
          })
        } else {
          // Webbläsaren har stöd men har inte installerat (exempelvis första besök eller incognito)
          console.log('Ingen serviceworker eller sparade assets alternativt redan uppdaterad, laddar om sidan')
          window.location.reload()
        }
      } catch (error) {
        // Webbläsaren har stöd men är inte kompatibel med serviceworkern så den fungerar som vanliga spa
        console.log('Installation av serviceworker misslyckades, laddar om sidan')
        console.error('Service worker installation error:', error)
        window.location.reload()
      }
    },
    setupUpdateListeners (registration) {
      // Bevakar uppdateringar som triggas nedan och laddar om när det är klart
      registration.addEventListener('updatefound', () => {
        const newWorker = registration.installing
        newWorker.addEventListener('statechange', (e) => {
          if (e.target.state === 'activated') {
            console.log('Uppdatering klar, laddar om sidan')
            window.location.reload()
          }
        })
      })

      // För att upptäcka uppdateringar där inget updatefound event skapas
      // Räcker inte med enbart detta så ovan behövs också
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        console.log('Uppdatering klar, laddar om sidan')
        window.location.reload()
      })
    },

    checkForWaitingUpdate (registration) {
      // registration är installerad version, registration.waiting är den nya versionen
      if (registration.waiting) {
        console.log('Hittat uppdatering som väntar, tvingar uppdatering')
        // PWA kan normalt inte bli uppdaterade förrän alla flikar stängts, en vanlig reload räcker inte
        // Denna metod tvingar fram uppdateringen ändå så att en reload ger den nya versionen efteråt
        registration.waiting.postMessage({ type: 'SKIP_WAITING' })
      } else {
        console.log('Ingen väntande uppdatering hittades')
      }
    }
  }
}
</script>
