<template>
<div>
  <v-dialog v-model="show" max-width="1000px" :fullscreen="isMobile">
    <v-card class="checkin-dialog" tile>
      <v-toolbar
        style="height: auto;"
        elevation="0"
        class="py-3 toolbar-with-subheader"
        color="white"
      >
        <div class="toolbar-titles-container">
          <v-toolbar-title class="pl-5">Incheckning</v-toolbar-title>
          <v-toolbar-title class="pl-5 toolbar-subheader">{{ subTitle }}</v-toolbar-title>
        </div>
        <v-spacer></v-spacer>
        <v-btn icon v-if="isMobile" @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-sheet
        class="d-flex overflow-y-auto"
        style="border-top: 1px solid lightgrey; min-height: 400px;"
      >
        <div
          v-if="isCheckingIn"
          class="pa-6 ma-auto d-flex flex-column align-self-stretch align-center"
          :class="{ 'd-block py-2': isMobile }"
        >
          <h3>Checkar in</h3>
          <v-progress-circular
            indeterminate
            size="48"
            class="my-4"
            color="secondary"
          ></v-progress-circular>
        </div>
        <div v-else style="width: 100%;" class="pa-4">
          <v-alert
            v-if="isCheckedIn"
            dense
            text
            type="success"
            class="d-block status-alert"
          >
            <div class="d-flex">
              <div class="flex-grow-1 my-auto">Bokningen har checkats in</div>
              <v-btn outlined color="success" class="ml-auto" @click="openBookingDialog">Öppna bokning</v-btn>
            </div>
          </v-alert>
          <v-alert
            v-else-if="hasError"
            dense
            text
            type="error"
            class="d-block status-alert"
          >
            <div class="d-flex">
              <div class="flex-grow-1 my-auto">{{ checkInErrorMessage }}</div>
              <v-btn outlined color="error" class="ml-auto" @click="openBookingDialog">Öppna bokning</v-btn>
            </div>
          </v-alert>

          <v-alert
            v-if="isLateCheckin || isEarlyCheckin"
            dense
            text
            :type="isLateCheckin ? 'info' : 'warning'"
            class="d-block status-alert"
          >
            <div class="d-flex">
              <div class="flex-grow-1 my-auto">Bokningen har checkats in {{ isEarlyCheckin ? 'tidigt' : 'sent' }}</div>
              <v-btn
                @click="openAccommodationChargesDialog"
                outlined
                :color="isLateCheckin ? 'info' : 'warning'"
                class="ml-auto"
              >
                Se och redigera platsavgifter
              </v-btn>
            </div>
          </v-alert>

          <v-row>
            <v-col>
              <v-card flat outlined>
                <booking-data-list
                  :is-loading="!booking"
                  :booking="booking"
                  inside-dialog
                  :hidden-sections="['cancellation-policy', 'booking', 'channel']"
                  customer-clickable
                  position-clickable
                  allow-reverse-checkin
                  allow-reverse-checkout
                ></booking-data-list>
              </v-card>
              <v-card flat class="mt-4">
                <booking-input-remarks
                  :is-open="show"
                  :booking-remarks="booking && booking.remarks"
                  :booking-id="booking && booking.id"
                  @remarks-updated="remarksUpdated"
                ></booking-input-remarks>
              </v-card>
            </v-col>
            <v-col>
              <tally-order
                v-if="booking?.access_driver === 'tally'"
                :booking="booking"
                :is-open="show"
                :is-loading="!booking"
                default-tab="card-list"
              ></tally-order>
            </v-col>
          </v-row>
        </div>
      </v-sheet>
    </v-card>
  </v-dialog>
  <accommodation-charges-dialog
    :booking="booking"
    :show="showAccommodationChargesDialog"
    @close="closeAccommodationChargesDialog"
    :loading="isFetchingCalendarItems"
    :calendar-items="accommodationChargesCalendarItems"
  ></accommodation-charges-dialog>
</div>
</template>

<script>
import BookingService from '@/services/BookingService.js'

import { mapState } from 'vuex'

import BookingDataList from './DataList.vue'
import BookingInputRemarks from './InputRemarks.vue'
import TallyOrder from '@/components/tally/Order.vue'
import AccommodationChargesDialog from './AccommodationChargesDialog.vue'

export default {
  name: 'CheckinDialog',
  components: {
    BookingDataList,
    BookingInputRemarks,
    TallyOrder,
    AccommodationChargesDialog
  },
  data: () => ({
    showAccommodationChargesDialog: false,
    isFetchingCalendarItems: false
  }),
  computed: {
    ...mapState(
      {
        booking: state => state.booking.checkinDialog.booking,
        isCheckingInBookingIds: state => state.booking.isCheckingInBookingIds,
        checkInErrorMessage: state => state.booking.checkinDialog.errorMessage,
        accommodationChargesCalendarItems: state => state.booking.checkinDialog.accommodationChargesCalendarItems
      }
    ),
    show: {
      get () {
        return this.$store.state.booking.checkinDialog.show
      },
      set (value) {
        this.$store.commit('booking/SET_SHOW_CHECKIN_DIALOG', value)
      }
    },
    hasError: function () {
      return this.checkInErrorMessage !== ''
    },
    isMobile: function () {
      return this.$vuetify.breakpoint.mdAndDown
    },
    subTitle: function () {
      if (!this.booking) {
        return ''
      }
      return `${this.booking.title}, ${this.booking.subtitle}`
    },
    isCheckingIn: function () {
      return this.booking && this.isCheckingInBookingIds.includes(this.booking.id)
    },
    isCheckedIn: function () {
      return this.booking && this.booking.checked_in_at != null
    },
    isSeasonBooking: function () {
      return this.booking && this.booking.type === 'season'
    },
    isLateCheckin: function () {
      return !this.isSeasonBooking && this.isCheckedIn && this.booking.checked_in_at.startOf('day').isAfter(this.booking.check_in)
    },
    isEarlyCheckin: function () {
      return !this.isSeasonBooking && this.isCheckedIn && this.booking.checked_in_at.startOf('day').isBefore(this.booking.check_in)
    },
    checkOutDateFormatted: function () {
      if (this.booking.checked_out_at) {
        return window.conversion.toHumanReadableDate(this.booking.checked_out_at)
      } else {
        return window.conversion.toHumanReadableDate(this.booking.check_out)
      }
    },
    checkInDateFormatted: function () {
      if (this.booking.checked_in_at) {
        return window.conversion.toHumanReadableDate(this.booking.checked_in_at)
      } else {
        return window.conversion.toHumanReadableDate(this.booking.check_in)
      }
    }
  },
  methods: {
    remarksUpdated: function ({ bookingId, remarks }) {
      if (this.booking && this.booking.id === bookingId) {
        this.booking.remarks = remarks
      }
    },
    close: function () {
      this.show = false
    },
    openBookingDialog: function () {
      this.$store.dispatch('booking/openDialogById', { bookingId: this.booking.id })
      this.close()
    },
    openAccommodationChargesDialog: function () {
      this.$store.commit('booking/SET_CHECKIN_DIALOG_ACCOMMODATION_CHARGES_CALENDAR_ITEMS', [])
      this.isFetchingCalendarItems = true
      this.showAccommodationChargesDialog = true
      const bookingId = this.booking.id
      BookingService.getAccommodationChargesCalendarItems(bookingId)
        .then(({ data }) => {
          if (data.status === 'success') {
            window.enrich.enrichCalendarItem(data.data.calendar_items)
            this.$store.commit('booking/SET_CHECKIN_DIALOG_ACCOMMODATION_CHARGES_CALENDAR_ITEMS', data.data.calendar_items)
          }
        })
        .finally(() => {
          this.isFetchingCalendarItems = false
        })
    },
    closeAccommodationChargesDialog: function () {
      this.showAccommodationChargesDialog = false
    }
  }
}
</script>
