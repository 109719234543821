import API from './API.js'

export default {
  search (input) {
    return API.httpClient.get('search', {
      params: {
        input
      }
    })
  },
  searchCustomer (input) {
    return API.httpClient.get('search/customer', {
      params: {
        input
      }
    })
  }
}
