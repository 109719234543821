import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@fontsource/roboto-mono'
import '@mdi/font/css/materialdesignicons.css'
import './coi/campingonlineicons.css'
import 'flag-icons/css/flag-icons.min.css'
import router from './router'
import store from './store'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import './registerServiceWorker'

// Fel visas i console lokalt och hos Sentry i produktion, gör det mycket lättare för oss att se vad som har hänt hos kunderna
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    Vue,
    dsn: 'https://44776c2db953484499d3cc39fa289797@o1418389.ingest.sentry.io/6761519',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['localhost', /^.*\.campingonline\.se$/, 'app.campingonline.se', /^\//]
      })
      // new Sentry.Replay()
    ],
    logErrors: true,
    tracesSampleRate: 1.0, // Ökar tillfälligt prestandamätningarna första veckorna
    // tracesSampleRate: 0.1, // räcker med att vi har ett urval på 10% för prestandadelen
    release: process.env.VUE_APP_VERSION,
    beforeSend (event, hint) {
      const error = hint.originalException
      if (error.name === 'NavigationDuplicated' || error.message?.includes('Navigation cancelled')) {
        return null
      }
      return event
    }
    // replaysSessionSampleRate: 0.1,
    // replaysOnErrorSampleRate: 1.0
  })
}

Vue.config.productionTip = false

/* Day.js and plugins */
require('dayjs/locale/sv')
window.dayjs = require('dayjs')
window.dayjs.locale('sv')
const isYesterday = require('dayjs/plugin/isYesterday')
window.dayjs.extend(isYesterday)
const isToday = require('dayjs/plugin/isToday')
window.dayjs.extend(isToday)
const isTomorrow = require('dayjs/plugin/isTomorrow')
window.dayjs.extend(isTomorrow)
const isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
window.dayjs.extend(isSameOrBefore)
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
window.dayjs.extend(isSameOrAfter)
const isBetween = require('dayjs/plugin/isBetween')
window.dayjs.extend(isBetween)
const weekOfYear = require('dayjs/plugin/weekOfYear')
window.dayjs.extend(weekOfYear)
const minMax = require('dayjs/plugin/minMax')
window.dayjs.extend(minMax)
const isBankHoliday = require('@/mixins/dayjs/isBankHoliday').default // egen plugin
window.dayjs.extend(isBankHoliday)
const weeksInMonth = require('@/mixins/dayjs/weeksInMonth').default // egen plugin
window.dayjs.extend(weeksInMonth)
const isCurrentYear = require('@/mixins/dayjs/isCurrentYear').default // egen plugin
window.dayjs.extend(isCurrentYear)

window.conversion = require('@/plugins/conversion.js').default
window.enrich = require('@/plugins/enrich.js').default
window.helper = require('@/plugins/helper.js').default

window.mainVM = new Vue({
  vuetify,
  router,
  store,
  created () {
    console.log('Vue created')
    router.afterEach((to, from) => {
      store.dispatch('navigated', { from, to })
    })

    store.dispatch('refreshNow')
    setInterval(function () {
      store.dispatch('refreshNow')
    }, 1000)

    store.dispatch('initialiseStore')
  },
  render: h => h(App)
}).$mount('#app')

window.scannerVM = new Vue({
  data: {
    events: [],
    logToConsole: false, // true för att köra console.log
    hasTally: true, // TODO: CO-1135 hårdkodat
    respondTo: '',
    respondToPayload: null,
    requestedMultipleInputs: false,
    currentPageName: '',
    maxTimeBetweenEvents: 50 // Rensar events först om det var mer än 50ms sedan senaste
  },
  methods: {
    onkeydown (e) {
      // Loggning för test
      if (this.logToConsole) {
        console.log(`keydown - key: ${e.key}, keycode: ${e.keyCode}, timeStamp: ${e.timeStamp}`)
      }
      // Sparar bara siffor input just nu, inkl hexadecimala tal
      if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 65 && e.keyCode <= 70)) {
        if (this.events.length >= 1 && e.timeStamp - this.events[this.events.length - 1].timeStamp > this.maxTimeBetweenEvents) {
          this.events = []
        }
        this.events.push(e)
      }
      // "Avslutar" vid Enter
      if (e.keyCode === 13) {
        this.onenter(e)
      }
      // Ctrl + f
      if (e.keyCode === 70 && e.ctrlKey) {
        e.preventDefault()
        document.getElementById('app-search-input').focus()
      }
    },
    onenter (e) {
      // Loggning för test
      if (this.logToConsole) {
        console.log('onenter', this.events.length)
        if (this.events.length >= 8) {
          console.log('ts diff', this.events[7].timeStamp - this.events[0].timeStamp)
        }
      }

      /*  Validering:
          Det har kommit minst 8 tecken på 200ms, följt av Enter ->
            Det finns en respondTo ->
              skicka svaret till den metoden
            Det finns ingen respondTo ->
              Om på kassasidan ELLER campingen använder inte Tally (antag alltid att det är en streckkod) ->
                Strängen matchar en streckkod på en existerande produkt ->
                  *Lägg produkten i varukorgen*
                Strängen matchar inte en streckkod på en existerande produkt ->
                  *Öppna produkt formuläret*
              Om på någon annan sida ->
                Strängen matchar en streckkod på en existerande produkt ->
                  *Lägg produkten i varukorgen*
                Strängen matchar inte en streckkod på en existerande produkt ->
                  *Skicka till server för att identifiera tallykort*

          Behövs det lösningar för när det inte stämmer?
            Vad kan "inte stämma"?
              Användaren skannar tallykort på kassasidan => Ska vi kontrollera om input innehåller bokstäver också? eller bättre att stödja streckkoder med bokstäver kanske?
              Användaren försöker lägga till ny produkt på annan sida än kassan => Ska vi kontrollera längden på input, tally är alltid 8 eller 10 tecken, kan detta komma att ändras?
      */
      if (this.events.length >= 8 && (this.events[7].timeStamp - this.events[0].timeStamp) < 200) {
        e.preventDefault()
        let input = ''
        this.events.forEach(event => {
          input += event.key
        })
        if (this.logToConsole) {
          console.log('input', input)
        }
        this.events = [] // Rensar events efter Enter

        if (this.respondTo !== '') {
          window.mainVM.$store.dispatch(this.respondTo, { input, param: this.respondToPayload })
          if (!this.requestedMultipleInputs) {
            // Återställer respondTo efter första input om inte specifikt efterfrågade flera inputs
            this.respondTo = ''
            this.respondToPayload = null
          }
        } else {
          if (!this.hasTally || this.currentPageName === 'PosRegister') {
            // Campingen använder inte tally, eller användaren befinner sig på kassasidan
            window.mainVM.$store.dispatch('product/scannedBarcode', input)
          } else {
            // kolla om input matchar mot en streckkod på existerande produkter
            const product = window.mainVM.$store.state.product.products.find(prod => prod.barcode === input)
            if (product) {
              // Produkt finns
              if (this.currentPageName === 'Products') {
                // På produktsidan, visa produkten på produktsidan
                router.push({ name: 'Products', params: { id: product.id } })
              } else {
                // Annars lägg i varukorgen
                window.mainVM.$store.dispatch('product/scannedBarcode', input)
              }
              return false
            }

            // om inte finns på existerande produkt, kolla om finns bland tallykorten
            const cardUid = parseInt(input, 16)
            if (!isNaN(cardUid)) {
              window.mainVM.$store.commit('tally/ADD_RECENTLY_SCANNED_UID', cardUid)
              const tallyCard = window.mainVM.$store.state.tally.activeTallyCards.find(card => card.card_uid === cardUid)
              if (tallyCard) {
                window.mainVM.$store.dispatch('booking/openDialogById', { bookingId: tallyCard.booking_id })
                return false
              }
            }

            if (/^[0-9]+$/.test(input)) {
              // input innehåller endast siffror, vi antar att det är en streckkod och öppnar product form - finns risk att det är tallykort men den risken kan vi ta
              window.mainVM.$store.dispatch('product/scannedBarcode', input)
              return false
            }

            window.mainVM.$store.dispatch('snackbars/createSnackbar', {
              color: 'warning',
              text: 'Oidentifierat objekt'
            })
          }
        }
      } else {
        // Loggning för test
        if (this.logToConsole) {
          console.log('No code') // Har inte matat in 8 siffror inom 200ms
        }
      }
    },
    requestInput (respondTo, payload = null, multiple = false) {
      this.respondTo = respondTo
      this.respondToPayload = payload
      this.requestedMultipleInputs = multiple
    },
    abortInputRequest (respondTo) {
      if (this.respondTo === respondTo) {
        this.respondTo = ''
        this.respondToPayload = null
        this.requestedMultipleInputs = false
      }
    },
    setCurrentPageName (val) {
      this.currentPageName = val
    }
  },
  created () {
    document.addEventListener('keydown', this.onkeydown)
  }
})
