<template>
<v-dialog
  v-model="show"
  max-width="640"
  :fullscreen="isMobile"
>
  <v-card class="changelog-dialog" :tile="isMobile">
    <v-card-title class="text-h5 d-flex flex-row">
      <div class="text-h5 p-md-absolute">Nyheter</div>
      <div v-if="!isMobile" class="full-width d-flex" style="padding-right: 17px;"><!-- 17px kompenserar för scrollbaren i dialogen så loggan blir centrerad i relation till innehållet i dialogen -->
        <v-img
          :src="require('@/assets/logos/logo-black.svg')"
          contain
          :height="42"
          :width="160"
          class="mx-auto"
          alt="CampingOnline"
        ></v-img>
      </div>
      <v-spacer></v-spacer>
      <v-btn icon class="d-flex d-md-none" @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <v-tabs color="secondary" v-model="selectedVersion">
      <v-tab
        v-for="version in availableVersions"
        :key="version"
        :href="`#${version}`"
      >
        {{ version }}
      </v-tab>
    </v-tabs>
    <div class="d-flex overflow-y-auto full-width changelog-dialog-body">
      <v-fade-transition>
        <div class="full-width" :class="{ 'd-none': isFetching, 'd-flex': !isFetching }">
          <!-- <div v-if="isFetching" class="ma-auto">
            <v-progress-circular
              indeterminate
              :size="50"
              color="primary"
              class="ma-auto"
            ></v-progress-circular>
          </div> -->
          <div class="ma-auto">
            <div v-html="changelog"></div>
          </div>
        </div>
      </v-fade-transition>
    </div>
  </v-card>
</v-dialog>
</template>

<script>
import PortalService from '@/services/PortalService.js'

export default {
  name: 'ChangelogDialog',
  components: {},
  props: {
    value: Boolean
  },
  data: () => ({
    availableVersions: ['2.0', '2.1'],
    selectedVersion: '',
    changelog: '',
    isFetching: false
  }),
  computed: {
    show: {
      get: function () {
        return this.value
      },
      set: function (val) {
        this.$emit('input', val)
      }
    },
    isMobile: function () {
      return this.$vuetify.breakpoint.mdAndDown
    }
  },
  methods: {
    close: function () {
      this.show = false
    },
    loadUpdates: function (version) {
      this.isFetching = true
      this.changelog = ''
      PortalService.getChangelog(version)
        .then(({ data }) => {
          console.log('getChangelog', data)
          if (data.status === 'success') {
            if (version === this.selectedVersion) {
              this.changelog = data.data.changelog
            }
          } else {
            this.changelog = data.error_message
          }
        })
        .catch(() => {
          //
        })
        .finally(() => {
          if (version === this.selectedVersion) {
            this.isFetching = false
          }
        })
    }
  },
  watch: {
    show: function () {
      if (this.show) {
        this.selectedVersion = this.availableVersions[this.availableVersions.length - 1]
      } else {
        this.selectedVersion = ''
      }
    },
    selectedVersion: function () {
      this.changelog = ''
      if (this.selectedVersion !== '') {
        this.loadUpdates(this.selectedVersion)
      }
    }
  },
  mounted () {
    this.selectedVersion = this.availableVersions[this.availableVersions.length - 1]
  }
}
</script>
