<template>
  <div class="payments-timeline">
    <v-timeline
      dense
    >
      <v-timeline-item
        v-if="timelineItems.length === 0"
        fill-dot
        class="align-center"
      >
        <template v-slot:icon>
          <v-icon color="white">mdi-currency-usd-off</v-icon>
        </template>
        <p class="text-body-2 my-auto">
          Inga betalningar har gjorts på den här fakturan
        </p>
      </v-timeline-item>

      <v-timeline-item
        v-for="timelineItem in timelineItems"
        :key="timelineItem.client_id"
        fill-dot
        :color="timelineItem.color"
        :class="{ 'deleted-payment': timelineItem.isDeleted }"
      >
        <template v-slot:icon>
          <v-progress-circular
            v-if="isLoadingReverseApplicationIds.includes(timelineItem.id) || isLoadingReversePaymentIds.includes(timelineItem.paymentId)"
            indeterminate
            size="24"
            class=""
            color="white"
          ></v-progress-circular>
          <v-icon v-else color="white">{{ timelineItem.icon }}</v-icon>
        </template>
        <v-row class="pt-1 mx-0">
          <v-col cols="3" class="d-flex flex-column">
            <div class="my-auto payment-date">{{ timelineItem.paidAt }}</div>
          </v-col>
          <v-col>
            <div class="payment-title"><strong>{{ timelineItem.title }}</strong></div>
            <div class="text-caption payment-subtitle">
              {{ timelineItem.subtitle }}
            </div>
            <div v-if="timelineItem.isDeleted" class="text-caption payment-sub-subtitle">
              ångrad: {{ timelineItem.deletedAtFormatted }}
            </div>
            <div v-else-if="timelineItem.isAppliedOnADifferentDay" class="text-caption payment-sub-subtitle">
              registrerad: {{ timelineItem.appliedAt }}
            </div>
          </v-col>
          <v-col cols="2" class="d-flex">
            <v-menu
              offset-x
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  class="ml-auto my-auto"
                >
                  <v-icon>
                    mdi-dots-vertical
                  </v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-list>
                  <v-list-item v-if="timelineItem.isPayment && !timelineItem.isRefund && !timelineItem.isCustomerCreditApplication && !timelineItem.disableReverse">
                    <v-list-item-title>
                      <v-btn
                        text
                        color="error"
                        block
                        @click="reversePayment(timelineItem)"
                        :loading="isLoadingReversePaymentIds.includes(timelineItem.paymentId)"
                        :disabled="timelineItem.isDeleted || isLoadingReverseApplicationIds.includes(timelineItem.id)"
                      >Ångra betalning</v-btn>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="timelineItem.isPayment && !timelineItem.isRefund && !timelineItem.disableReverse">
                    <v-list-item-title>
                      <v-btn
                        text
                        color="error"
                        block
                        @click="reversePaymentApplication(timelineItem.id)"
                        :loading="isLoadingReverseApplicationIds.includes(timelineItem.id)"
                        :disabled="timelineItem.isDeleted || isLoadingReversePaymentIds.includes(timelineItem.paymentId)"
                      >Omplacera betalning</v-btn>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="timelineItem.isPayment && timelineItem.isRefund && !timelineItem.disableReverse">
                    <v-list-item-title>
                      <v-btn
                        text
                        color="error"
                        block
                        @click="reverseRefund(timelineItem)"
                        :loading="isLoadingReversePaymentIds.includes(timelineItem.paymentId)"
                        :disabled="timelineItem.isDeleted || isLoadingReverseApplicationIds.includes(timelineItem.id)"
                      >Ångra återbetalning</v-btn>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="!timelineItem.isPayment">
                    <v-list-item-title>
                      <v-btn
                        text
                        color="primary"
                        block
                        @click="openOtherInvoice(timelineItem.id)"
                      >{{ timelineItem.openInvoiceButtonText  }}</v-btn>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title>
                      <v-btn text color="primary" block disabled>Visa verifikat</v-btn>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </v-col>
        </v-row>
      </v-timeline-item>

      <v-timeline-item
        fill-dot
        color="success"
      >
        <template v-slot:icon>
          <v-icon color="white">mdi-plus</v-icon>
        </template>
        <v-btn
          color="success"
          @click="startRegisterPayment"
          :class="{ 'btn-two-rows': isMobile }"
        >
          Registrera en betalning
        </v-btn>
        <v-btn
          v-if="allowCrediting"
          color="error"
          outlined
          @click="startRegisterCredit"
          :class="{ 'ml-2': !isMobile, 'btn-two-rows mt-4': isMobile }"
        >
          Kreditera faktura
        </v-btn>
        <v-btn
          v-if="allowConvertToCustomerCredit"
          color="warning"
          outlined
          @click="convertToCredit"
          :loading="isLoadingConvertToCredit"
          :class="{ 'ml-2': !isMobile, 'btn-two-rows mt-4': isMobile }"
        >
          Omvandla till tillgodo
        </v-btn>
      </v-timeline-item>
    </v-timeline>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'PaymentsTimeline',
  props: {
    paymentApplications: Array,
    creditApplications: Array,
    invoiceId: Number,
    allowCrediting: Boolean,
    allowConvertToCustomerCredit: Boolean
  },
  data: () => ({}),
  computed: {
    ...mapState(
      {
        isLoadingReverseApplicationIds: state => state.invoice.isLoadingReverseApplicationIds,
        isLoadingReversePaymentIds: state => state.invoice.isLoadingReversePaymentIds,
        isLoadingConvertToCredit: state => state.invoice.isLoadingConvertToCredit
      }
    ),
    isMobile: function () {
      return this.$vuetify.breakpoint.mdAndDown
    },
    timelineItems: function () {
      const paymentApplications = [...this.paymentApplications].map(pa => {
        pa.is_payment_application = true
        pa.is_credit_application = false
        return pa
      })
      const creditApplications = [...this.creditApplications].map(pa => {
        pa.is_payment_application = false
        pa.is_credit_application = true
        return pa
      })
      const allApplications = paymentApplications.concat(creditApplications)
      allApplications.sort((a, b) => {
        // b före a = 1, a före b = -1
        const aDate = a.is_payment_application ? window.dayjs(a.payment.paid_at) : window.dayjs(a.credited_at)
        const bDate = b.is_payment_application ? window.dayjs(b.payment.paid_at) : window.dayjs(b.credited_at)

        if (aDate.isBefore(bDate)) {
          // a betald/krediterad för b
          return -1
        } else {
          return 1
        }
      })

      return allApplications.map(application => {
        const isPayment = application.is_payment_application
        if (isPayment) {
          const paidAt = window.dayjs(application.payment.paid_at)
          const appliedAt = window.dayjs(application.applied_at)
          const isDeleted = application.deleted_at !== null
          const deletedAtFormatted = isDeleted ? window.conversion.toHumanReadableDate(window.dayjs(application.deleted_at)) : ''
          return {
            isPayment,
            client_id: `p${application.id}`,
            id: application.id,
            paymentId: application.payment_id,
            isCustomerCreditApplication: application.is_customer_credit_application,
            paidAt: application.is_customer_credit_application ? window.conversion.toHumanReadableDate(appliedAt) : window.conversion.toHumanReadableDate(paidAt),
            icon: application.is_customer_credit_application ? 'mdi-account-cash-outline' : application.payment.payment_method.icon_class_name,
            color: application.is_customer_credit_application ? 'secondary' : application.payment.payment_method.default_gui_color,
            isAppliedOnADifferentDay: !paidAt.startOf('day').isSame(appliedAt.startOf('day')),
            appliedAt: window.conversion.toHumanReadableDate(appliedAt),
            title: `${application.paid_amount / 100} kr SEK`,
            isRefund: application.paid_amount < 0,
            subtitle: `Betalningsmetod: ${application.is_customer_credit_application ? 'Tillgodo' : application.payment.payment_method.name}`,
            disableReverse: application.payment.payment_method.gateway === 'customer_credit' || application.payment.payment_method.gateway === 'stripe',
            isDeleted,
            deletedAtFormatted,
            openInvoiceButtonText: ''
          }
        } else {
          const creditedAt = window.dayjs(application.credited_at)
          let title = ''
          let subtitle = ''
          if (this.invoiceId === application.invoice_id) {
            title = `${application.credited_amount / 100} kr SEK`
            subtitle = `Krediterat av faktura ${application.credit_invoice_id}`
          } else {
            title = `${-application.credited_amount / 100} kr SEK`
            subtitle = `Krediterat faktura ${application.invoice_id}`
          }
          let openInvoiceButtonText = ''
          if (this.invoiceId === application.invoice_id) {
            openInvoiceButtonText = 'Visa kreditfaktura'
          } else {
            openInvoiceButtonText = 'Visa krediterad faktura'
          }
          return {
            isPayment,
            client_id: `p${application.id}`,
            id: application.id,
            isCustomerCreditApplication: false,
            paidAt: window.conversion.toHumanReadableDate(creditedAt),
            icon: 'mdi-swap-horizontal',
            color: 'secondary',
            isAppliedOnADifferentDay: false,
            appliedAt: window.conversion.toHumanReadableDate(creditedAt),
            title,
            isRefund: false,
            subtitle,
            isDeleted: false,
            deletedAtFormatted: '',
            openInvoiceButtonText
          }
        }
      })
    }
  },
  methods: {
    startRegisterPayment: function () {
      this.$emit('start-register-payment')
    },
    startRegisterCredit: function () {
      this.$emit('start-register-credit')
    },
    reversePayment: function (paymentApplication) {
      console.log('paymentApplication', paymentApplication)
      const paymentId = paymentApplication.paymentId
      const invoiceId = this.invoiceId
      this.$store.commit('SET_OPEN_CONFIRMATION_DIALOG', {
        header: 'Ångra en registrerad betalning',
        body: 'Är du säker på att du vill ångra denna registrerade betalningen? Denna metod är till för att ta bort betalningar som aldrig genomförts, den är INTE till för att göra återbetalningar. Om betalningen är uppdelad på flera fakturor så påverkas samtliga fakturor.',
        confirmationFunction: 'invoice/reverseInvoicePayment',
        confirmationArgument: { paymentId, invoiceId },
        withTimer: true
      })
    },
    reversePaymentApplication: function (paymentApplicationId) {
      this.$store.commit('SET_OPEN_CONFIRMATION_DIALOG', {
        header: 'Omplacera betalning',
        body: 'När du omplacerar en betalning så tas betalningen bort från fakturan och tillförs kundens tillgodosaldo så att du kan applicera den på någon annan av kundens fakturor eller använda den i kassan.',
        confirmationFunction: 'invoice/reverseInvoicePaymentApplication',
        confirmationArgument: paymentApplicationId,
        withTimer: true
      })
    },
    reverseRefund: function (paymentApplication) {
      const paymentId = paymentApplication.paymentId
      const invoiceId = this.invoiceId
      this.$store.commit('SET_OPEN_CONFIRMATION_DIALOG', {
        header: 'Ångra en registrerad återbetalning',
        body: 'Är du säker på att du vill ångra denna registrerade återbetalningen? Denna metod är till för att ta bort återbetalningar som aldrig genomförts. Tillgodot kommer att återföras till kreditfakturan.',
        confirmationFunction: 'invoice/reverseInvoicePayment',
        confirmationArgument: { paymentId, invoiceId },
        withTimer: true
      })
    },
    openOtherInvoice: function (creditApplicationId) {
      const ca = this.creditApplications.find(c => c.id === creditApplicationId)
      if (this.invoiceId === ca.invoice_id) {
        // Detta är fakturan, öppna kreditfakturan
        this.$store.dispatch('invoice/openDialogById', { invoiceId: ca.credit_invoice_id })
      } else {
        // Detta är kreditfakturan, öppna fakturan
        this.$store.dispatch('invoice/openDialogById', { invoiceId: ca.invoice_id })
      }
    },
    convertToCredit: function () {
      this.$store.dispatch('invoice/convertToCredit', { invoiceId: this.invoiceId })
    }
  },
  watch: {}
}
</script>
