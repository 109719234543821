<template>
  <v-autocomplete
    v-model="selection"
    flat
    solo-inverted
    hide-details
    prepend-inner-icon="mdi-magnify"
    :label='searchLabel'
    :menu-props="{ maxHeight: '70%' }"
    id="app-search-input"
    :items="displayedSearchResults"
    :search-input.sync="searchInput"
    :loading="isLoading"
    :error="!!errorMessage"
    return-object
    no-filter
    hide-no-data
    append-icon=""
    color="secondary"
  >
    <template v-slot:item="data">
      <template v-if="!!errorMessage">
        <v-list-item-content style="">
          <v-list-item-title>
          <v-alert type="error" class="mx-2 my-0" dense text>
            {{ data.item.text }}
          </v-alert>
          </v-list-item-title>
        </v-list-item-content>
      </template>
      <template v-else>
        <v-list-item-avatar :color="data.item.avatar.color" class="justify-center">
          <span v-if="data.item.avatar.text" class="white--text" v-text="data.item.avatar.text"></span>
          <v-icon
            color="white"
            v-else-if="data.item.avatar.icon"
          >
            {{ data.item.avatar.icon }}
          </v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-html="data.item.title"></v-list-item-title>
          <v-list-item-subtitle v-html="data.item.subtitle"></v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </template>
  </v-autocomplete>
</template>

<script>
import SearchService from '@/services/SearchService.js'
import Fuse from 'fuse.js'
import { mapState } from 'vuex'

export default {
  name: 'AppSearch',
  data: () => ({
    isLoading: false,
    selection: null,
    errorMessage: '',
    searchResponse: null,
    fuse: null,
    fuseResults: []
  }),
  computed: {
    ...mapState(
      {
        products: state => state.product.products
      }
    ),
    searchInput: {
      get: function () {
        return this.$store.state.globalSearchString
      },
      set: function (val) {
        this.$store.commit('SET_GLOBAL_SEARCH_STRING', val)
      }
    },
    searchLabel: function () {
      if (this.$vuetify.breakpoint.smAndDown) {
        return 'Sök'
      } else {
        return 'Sök (tryck ctrl + F för att markera)'
      }
    },
    displayedSearchResults: function () {
      let displayedResults = []
      let isFirstSection = true
      if (this.errorMessage) {
        return [{
          disabled: true,
          text: this.errorMessage
        }]
      }
      if (this.searchResponse) {
        if (this.searchResponse.bookings.length > 0) {
          if (!isFirstSection) {
            displayedResults.push({
              divider: true
            })
          }
          isFirstSection = false
          displayedResults.push({
            header: 'Bokningar'
          })
          displayedResults = displayedResults.concat(this.searchResponse.bookings)
        }

        if (this.searchResponse.positions.length > 0) {
          if (!isFirstSection) {
            displayedResults.push({
              divider: true
            })
          }
          isFirstSection = false
          displayedResults.push({
            header: 'Platser'
          })
          displayedResults = displayedResults.concat(this.searchResponse.positions)
        }

        if (this.searchResponse.customers.length > 0) {
          if (!isFirstSection) {
            displayedResults.push({
              divider: true
            })
          }
          isFirstSection = false
          displayedResults.push({
            header: 'Kunder'
          })
          displayedResults = displayedResults.concat(this.searchResponse.customers)
        }

        if (this.searchResponse.products.length > 0) {
          if (!isFirstSection) {
            displayedResults.push({
              divider: true
            })
          }
          isFirstSection = false
          displayedResults.push({
            header: 'Produkter'
          })
          displayedResults = displayedResults.concat(this.searchResponse.products)
        }

        const options = {
          includeScore: true,
          includeMatches: true,
          keys: ['title', 'subtitle']
        }
        console.log('displayedResults', displayedResults)
        const fuse = new Fuse(displayedResults, options)
        console.log('fuse', fuse)
        const fuseResults = fuse.search(this.searchResponse.input)
        console.log('fuseResults', fuseResults)

        fuseResults.forEach(fs => {
          const results = displayedResults[fs.refIndex]
          fs.matches.forEach(match => {
            const originalText = results[match.key]
            let formattedText = ''
            if (this.searchResponse.input.length >= 3) {
              match.indices = match.indices.filter(i => {
                return i[1] + 1 - i[0] >= 3
              })
              // fuse.js kan i vissa fall ge indices som överlappar varandra, verkar vara en bugg i fuse.js baserat på github kommentarer, sorterar bort överlappande indices nedan eftersom formatteringen inte hanterar dessa
              match.indices = match.indices.filter((tag, i, self) => {
                return self.findIndex(x => tag[0] <= x[1] && tag[1] >= x[0]) === i
              })
              if (match.indices.length === 0) {
                return true
              }
            }
            formattedText += originalText.substring(0, match.indices[0][0])
            match.indices.forEach((i, j) => {
              if (j > 0) {
                formattedText += originalText.substring(match.indices[j - 1][1] + 1, i[0])
              }
              formattedText += '<strong>' + originalText.substring(i[0], i[1] + 1) + '</strong>'
            })
            formattedText += originalText.substring(match.indices[match.indices.length - 1][1] + 1)
            results[match.key] = formattedText
          })
        })
      }
      if (displayedResults.length === 0 && this.searchInput) {
        if (this.isLoading) {
          displayedResults.push({
            header: 'Söker...'
          })
        } else {
          displayedResults.push({
            header: 'Sökningen har inga resultat'
          })
        }
      }

      return displayedResults
    }
  },
  methods: {},
  watch: {
    searchInput (val) {
      this.errorMessage = ''

      if (val === null || val === '') {
        this.searchResponse = null
        if (val === this.searchInput) {
          this.isLoading = false
        }
        return false
      }

      if ((typeof val) === 'object') {
        this.searchInput = ''
        let product = null
        switch (val.type) {
          case 'customer':
            this.$store.dispatch('customer/openDialogById', { customerId: val.id })
            break

          case 'booking':
            this.$store.dispatch('booking/openDialogById', { bookingId: val.id })
            break

          case 'position':
            this.$router.push({ name: 'Map', params: { positionId: val.id } })
            break

          case 'booking_group':
            break

          case 'product':
            // Kan hända att produkten på servern inte finns lokalt, hämtar isåfall om produkter och kontrollerar igen
            product = this.products.find(prod => prod.id === val.id)
            if (product) {
              this.$store.dispatch('posCart/addToCart', product)
            } else {
              this.$store.dispatch('product/reloadProducts')
                .then(() => {
                  product = this.products.find(prod => prod.id === val.id)
                  if (product) {
                    this.$store.dispatch('posCart/addToCart', product)
                  }
                })
            }
            break

          default:
            break
        }
      } else {
        this.isLoading = true
        SearchService.search(val)
          .then(({ data }) => {
            if (val === this.searchInput) {
              this.searchResponse = data.data
            }
          })
          .catch((err) => {
            console.log('search error:', err)
            if (val === this.searchInput) {
              this.errorMessage = `Ett fel har inträffat, felkod: ${err.response ? err.response.status : 'okänd'}`
            }
          })
          .finally(() => {
            if (val === this.searchInput) {
              this.isLoading = false
            }
          })
      }
    }
  }
}
</script>
